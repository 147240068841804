<template>
  <v-row>
    <v-col
      cols="12">
      <v-form>
        <validation-observer
          ref="validationObserver">
          <v-card
            :title="$t('views.zones.manage.title')"
            :loading="loading"
            class="pa-4 weird-class">
            <v-card-title>
              {{ $t("crudActions.manage") + " " + $t("models.zones.multiple") }}
            </v-card-title>
            <!-- Zones Tabs -->
            <v-tabs
              v-model="tabs">
              <v-tab
                v-for="zone in serverData.params.zones"
                :key="zone.id">
                {{ zone.name }}
              </v-tab>
            </v-tabs>
            <!-- Zones Tabs Content -->
            <v-alert
              dense
              type="info"
              class="mt-3">
              {{ $t("views.zones.manage.one_zone_per_pool_info") }}
            </v-alert>
            <v-tabs-items
              v-if="!loading"
              v-model="tabs">
              <v-tab-item
                v-for="zone in serverData.params.zones"
                :key="zone.id">
                <!-- Zones Tabs Content -->
                <v-container>
                  <v-row>
                    <v-col
                      cols="12">
                      <!-- Pools -->
                      <v-row
                        class="d-flex justify-center">
                        <v-col
                          cols="12"
                          class="d-flex justify-center">
                          <h1> {{ $t("models.bettingPools.multiple") }} </h1>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <btn-group-text
                            :selected="form.bettingPoolZones.find(bpz => bpz.id === zone.id).poolIds"
                            :options="serverData.params.pools"
                            color="info"
                            attribute="reference"
                            class="d-flex justify-center"
                            multiple
                            @change="poolZonesChanged" />
                        </v-col>
                      </v-row>
                      <!-- Users -->
                      <v-row
                        class="d-flex justify-center">
                        <v-col
                          cols="12"
                          class="d-flex justify-center">
                          <h1> {{ $t("models.users.multiple") }} </h1>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <btn-group-text
                            :selected="form.userZones.find(uz => uz.id === zone.id).userIds"
                            :options="serverData.params.users"
                            multiple
                            class="d-flex justify-center"
                            color="info"
                            attribute="username"
                            @change="userZonesChanged" />
                        </v-col>
                      </v-row>
                      <v-alert
                        v-if="serverData.params.users.length === 0"
                        dense
                        outlined
                        type="error">
                        {{ $t("views.zones.manage.no_users_found") }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="$router.push({ name: $routes.zone.indexRoute.name })">
                {{ $t("actions.back") }}
              </v-btn>
              <v-spacer />
              <v-btn
                color="success"
                @click="updateZones">
                {{ $t("actions.save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import ContentLoader from "@/mixins/ContentLoader.js";
import { fetchUserAndPoolZones, getParams, manageUpdateZone } from "@/api/zone.js";
import { ValidationObserver } from "vee-validate";
import BtnGroupText from "@/components/inputs/BtnGroupText";

function data() {
  return {
    tabs: null,
    form: {
      bettingPoolZones: [],
      userZones: [],
    },
    serverData: {
      params: {
        zones: [],
        users: [],
        pools: [],
      },
    },
  };
}

export default {
  name: "ZoneManage",
  components: {
    BtnGroupText,
    ValidationObserver,
  },
  mixins: [ContentLoader],
  data,
  mounted() {
    this.$startLoading();
    getParams().
      then(response => {
        const { users, pools, zones } = response.data;

        this.serverData.params.users = users;
        this.serverData.params.pools = pools;
        this.serverData.params.zones = zones;

        fetchUserAndPoolZones().
          then(zonesResponse => {
            const { bettingPoolZones, userZones } = zonesResponse.data;

            this.form.bettingPoolZones = bettingPoolZones;
            this.form.userZones = userZones;
            this.$finishedLoading();
          });
      });
  },
  methods: {
    userZonesChanged(newUserZones) {
      this.form.userZones[this.tabs].userIds = newUserZones;
    },
    poolZonesChanged(newPoolZones) {
      const oldPoolZones = this.form.bettingPoolZones[this.tabs].poolIds;

      if (oldPoolZones.length < newPoolZones.length) {
        // Means we are adding a pool to a zone
        // Adding pool to this zone requires shutting it off on a different zone
        const newPoolZoneId = newPoolZones.find(pz => !oldPoolZones.includes(pz));

        this.form.bettingPoolZones.forEach((bpZones, index) => {
          if (bpZones.poolIds.includes(newPoolZoneId)) {
            const oldPoolHolder = this.form.bettingPoolZones[index].poolIds;

            oldPoolHolder.splice(oldPoolHolder.indexOf(newPoolZoneId), 1);
          }
        });
      }

      this.form.bettingPoolZones[this.tabs].poolIds = newPoolZones;
    },
    async updateZones() {
      const { validationObserver } = this.$refs,
        isValid = await validationObserver.validate();

      if (isValid) {
        manageUpdateZone(this.form).
          then(() => {
            this.$router.push({ name: this.$routes.zone.indexRoute.name });
          }).
          catch(error => this.handleErrorResponse(validationObserver, error));
      }
    }
  },
};
</script>

<style
  lang="scss"
  scoped>
</style>
